import { getUserList, queryIdTree, ajaxGetDictItems } from '@/api/api'
import { postAction, getAction, putAction } from '@/api/manage'
import { TreeSelect } from 'ant-design-vue'
import moment from 'moment'
import JDate from '@/components/jeecg/JDate'
import { mapMutations } from 'vuex'
import { simpleDebounce } from '@/utils/util'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const SHOW_ALL = TreeSelect.SHOW_ALL
const SHOW_CHILD = TreeSelect.SHOW_CHILD
export const DrawerMixins = {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isForm: {
      type: Boolean,
      default: false
    },
    isEditForm: {
      type: Boolean,
      default: false
    },
    curRow: {
      type: Object,
      default: () => ({})
    },
    noSlot: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: () => false
    },
    isMaskClosable: {
      type: Boolean,
      default: () => false
    },
    hideEditBtn: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    JDate
  },
  data() {
    return {
      formData: {},
      title: '详情',
      layout: {
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 15
        }
      },
      typeBackWordList: [],
      typeGlossaryList: [],
      textStyleTypeList: [],
      stageList: [],
      userList: [],
      userListByDeparts: [],
      departList: [],
      companyList: [],
      selectedUsers: [],
      selectedDepart: '',
      selectedDeparts: [],
      defaultDeparts: [],
      positionList: [],
      roleList: [],
      assistantUsers: [],
      platformList: [],
      selcetedAssistantUsers: [],
      positionSelected: '',
      isEdit: false,
      isSaved: false,
      SHOW_PARENT,
      confirmBtnDisable: true,
      btnLoading:false
    }
  },
  watch: {
    isEditForm(n) {
      if (n) {
        this.edit()
      }
    }
  },
  computed: {
    setTitle() {
      if (!this.isForm) return this.title
      return '新建'
    },
    getTreePositionList() {
      console.log({ 'this.positionList': this.positionList })
      let treePositionList = this.positionList
        .filter(i => i)
        .map(({ id: key, id: value, name: title }) => ({ title, key, value }))
      console.log({ treePositionList })
      return treePositionList
    },
    getTreeStageList() {
      console.log({ 'this.stageList': this.stageList })
      let treeStageList = this.stageList
        .filter(i => i)
        .map(({ id: key, stageId: value, stageName: title }) => ({ title, key, value }))
      console.log({ treeStageList })
      return treeStageList
    }
  },
  beforeDestroy() {
    this.clearEditItemSelects?.()
  },
  methods: {
    moment,
    ...mapMutations(['clearEditItemSelects', 'closeAllEditItemSelects']),
    closed() {
      const self = this
      if (this.isSaved || !this.isEdit) {
        if (this.isForm && !this.isSaved && typeof this.oldFormData == 'object') {
          this.inspectFormData()
        } else {
          self.exit()
        }
      } else {
        this.inspectFormData()
      }
    },
    inspectFormData() {
      const self = this
      let newData = JSON.stringify(this.formData)
      let oldData = JSON.stringify(this.oldFormData)
      if (newData != oldData) {
        this.$confirm({
          title: '提示',
          content: '您尚未保存数据，确定要退出吗？',
          onOk() {
            self.exit()
          }
        })
      } else {
        self.exit()
      }
    },
    exit() {
      let newData = JSON.stringify(this.formData)
      let oldData = JSON.stringify(this.oldFormData)
      if (newData != oldData) {
        this.$emit('closed', true)
      } else {
        this.$emit('closed', false)
      }
      this.initForm()
      this.isEdit = false
      this.isSaved = false
    },
    getUserList() {
      getUserList({ pageSize: 1000 }).then(({ success, data }) => {
        this.userList = data.records
      })
    },
    getUserListByDeparts() {
      console.log({ 'this.formData': this.formData })

      return postAction('/sys/user/queryUser', { departmentIds: this.formData.departmentIds }).then(
        ({ success, data }) => {
          if (success) {
            this.userListByDeparts = data
          }
        }
      )
    },
    getStageList() {
      getAction('/stage/list?pageSize=100').then(({ success, data }) => {
        this.stageList = data
      })
    },
    getDepartList() {
      queryIdTree().then(({ data }) => {
        this.departList = data
        this.companyList = JSON.parse(JSON.stringify(data))
        this.companyList.forEach(one => {
          one.children.forEach(two => {
            two.children = []
          })
        })
      })
    },
    getVocabularyList() {
      getAction('/guide/systemDefaultVocabularyConfig').then(({ success, data }) => {
        this.vocabularyList = data
      })
    },

    getDicts() {
      ajaxGetDictItems('terms_template_type').then(res => {
        this.typesList = res.data
      })
      ajaxGetDictItems('terms_template_field').then(res => {
        this.typeTitleList = res.data
      })
    },

    getDictItems() {
      // 屏蔽词类型
      ajaxGetDictItems('black_word_type').then(res => {
        this.typeBackWordList = res.data
      })
      ajaxGetDictItems('glossary_type').then(res => {
        this.typeGlossaryList = res.data
      })
    },

    getTextStyleType() {
      // 字体样式类型
      ajaxGetDictItems('text_style_type').then(res => {
        this.textStyleTypeList = res.data.map(item => ({ value: item.title, label: item.title }))
      })
    },

    changeDepart(v, t, { triggerNode }) {
      this.formData.department = {
        departId: triggerNode.value,
        departName: triggerNode.title
      }
    },
    changeDepartList(keys, t, { triggerNode }) {
      this.formData.departments = keys.map(item => {
        return {
          departId: item
        }
      })
    },
    changeStage(v, t, { triggerNode }) {
      this.formData.stage = {
        stageId: triggerNode.value,
        stageName: triggerNode.title
      }
    },
    changeStageList(keys, t, { triggerNode }) {
      this.formData.stages = keys.map(item => {
        return {
          stageId: item
        }
      })
    },
    changeKpiList(keys, t, { triggerNode }) {
      this.formData.kpiList = keys.map(item => {
        return {
          stageId: item
        }
      })
    },
    changeRejectList(keys, t, { triggerNode }) {
      this.formData.rejectList = keys.map(item => {
        return {
          stageId: item
        }
      })
    },
    changePositionList(keys, t, { triggerNode }) {
      console.log('changePositionList', { keys, t, triggerNode })
      this.formData.positions = keys.map(item => {
        return {
          positionId: item
        }
      })
    },
    changeMemberList(keys, t, { triggerNode }) {
      console.log('changeMemberList', { keys, t, triggerNode })
      this.formData.members = keys.map(item => {
        return {
          positionId: item
        }
      })
    },

    positionChange(id, option) {
      this.getAssistantUsers(id)
    },
    getPosition() {
      return getAction('/sys/position/list?pageSize=1000').then(({ data }) => {
        this.positionList = data.records
      })
    },
    getRoles() {
      return getAction('/sys/role/list?pageSize=1000').then(({ data }) => {
        this.roleList = data.records.filter(item => {
          return (
            item.roleCode != 'jianzhi' &&
            item.roleCode != 'zhongshen' &&
            item.roleCode != 'zhizuo' &&
            item.roleCode != 'jiaodui' &&
            item.roleCode != 'fanyi' &&
            item.roleCode != 'shangse'
          )
        })
      })
    },
    getAssistantUsers(id) {
      getAction('/sys/user/query_by_position', {
        positionId: id
      }).then(({ data }) => {
        this.assistantUsers = data
      })
    },
    getPlatformList() {
      getAction('/platform/list?pageSize=1000').then(({ data }) => {
        this.platformList = data.records
      })
    },

    getDictList() {
      getAction('/sys/dictItem/list').then(({ data }) => {
        this.platformList = data.records
      })
    },
    save: simpleDebounce(async function() {
      this.saveSim()
    }, 500),
    saveSim() {
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading=true
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            putAction(self.url.edit, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading=false
                self.closed()
              } else {
                self.btnLoading=false
                self.$message.error(res.message || res.msg)
              }
            })
          } else {
            console.log('新建URL', self.url.add)
            console.log('新建参数', self.formData)
            postAction(self.url.add, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.message)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading=false
                self.closed()
                self.closedModel()
              } else {
                self.btnLoading=false
                self.$message.error(res.message || res.msg)
              }
            })
          }
        }else{
          self.btnLoading=false
        }
      })
    },
    edit() {
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      this.formData = this.curRow
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.startEdit()
    },
    startEdit() {}
  }
}
